<script>
import {ajax_get_statisticData} from "../../../api/index"
export default {
    name:"basePage",
    data(){
        return {
            tableData:{},
            pageData:{
                pageNo: 1,
                pageSize: 10,
            },
            detailShow: false,
            detailData: [],
            detailPicData:[],
            exceptConfigForm:{
                business:["index", "companyCode", "companyName", "linkName", "phone", "companyTypeStr", "createTime", 
                         "production", "statusStr", "carBackUrl", "carFrontUrl", "proveUrl", "licenseUrl", "operate"],
                plan:["index", "purchaseOrderNumber", "statusStr", "operate","orderModel"],
                autoplan:["index", "statusStr", "operate","orderModel"],
                user:["index", "operate", "createTime"],
                auth:["index", "operate", "createTime"]
            },
            formDataOrigin:[],
            formData:[],
            formShow: false,
            formType:"add",
            formTitle:"新增",
            ajaxListMethods:"",
            ajaxGetData:{},
        }
    },
    methods:{
        ajax_get_statisticData(type){
            return new Promise((resolve, reject)=>{
                ajax_get_statisticData({type:type}).then(res=>{
                    if(res.code == 200){
                        resolve(res.result)
                    }else{
                        reject(res)
                    }
                }).catch(err=>{
                    reject(err)
                })
            })
        },
        generateDetailArr(){
          this.detailData = [];
          for(let element of this.tableData.tHead){
            if(element.prop !== "index" && element.prop !== "operate"){
                let obj = {
                    id: element.prop,
                    name: element.label,
                    value:""
                }
                if(element.type === "pic"){
                    this.detailPicData.push(obj)
                }else{
                    this.detailData.push(obj)
                }
                
            }
          }
        },
        generateFormArr(configName){
            console.log(configName, this.exceptConfigForm[configName])
            this.formData = [];
            for(let element of this.tableData.tHead){
                let exceptArr = this.exceptConfigForm[configName] || [];
                let index = exceptArr.findIndex(v=> element.prop === v);
                if(index === -1){
                    let placeHolderConfig = {
                        input:"请填写",
                        select:"请选择",
                    }
                    let placeholder = (placeHolderConfig[element.formType] || "") + element.label
                    let obj = {
                       id: element.prop,
                       name: element.label,
                       value: '',
                       type: element.formType,
                       check_type: 'required',
                       placeholder: placeholder,
                       unit: '',
                       slot: "",
                       options: [],
                       show:true,  
                    }
                    this.formDataOrigin.push(obj)
                }
            }
        },
        ajax_list_get(initPageNo){
            if(initPageNo){
                this.pageData.pageNo = 1;
            }
            if(!this.ajaxListMethods) return
            const params = {...this.pageData, ...this.ajaxGetData}
            this.ajaxListMethods(params).then(res=>{
                console.log(res)
                if(res.code == 200){
                    if(this.handerTableData){
                        const list = this.handerTableData(res.result.list)
                        this.tableData.tBody = this.$deepClone(list);
                        this.tableData.page.total = res.result.total
                    }else{
                        this.tableData.tBody = this.$deepClone(res.result.list);
                    }
                }
            }).catch(err=>{
                console.log("ajax_err:", err)
            })
        },
        tableToDetail(data){
            this.detailData.forEach(item=>{
                item.value = data[item.id]
            })
        },
        tableToForm(data){
            console.log(data,"data")
            console.log(this.formData,"data")
            this.formData.forEach(item=>{
                item.value = data[item.id]
            })
        },
        pageChange(val){
          this.ajaxGetData.pageNumber = val;
          this.ajax_list_get();
        },
        formInit(addFlag){
            this.formData = this.$deepClone(this.formDataOrigin);
            this.formTitle = addFlag ? "新增" : "编辑";
            this.formShow = true;
            this.formType = addFlag ? "add" : "edit";
        },
    }
}
</script>