export const tableData_business = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'companyCode',
            label:'企业编号',
            show:true,
            slot:'',
          },
        {
          prop:'companyName',
          label:'企业名称',
          show:true,
          slot:'',
        },
        {
            prop:'linkName',
            label:'联系人',
            show:true,
            slot:'',
        },
        {
            prop:'phone',
            label:'手机号',
            show:true,
            slot:'',
        },
        {
            prop:'companyTypeStr',//1、采购商；2经销商
            label:'企业类型',
            show:true,
            slot:'',
        },
        {
            prop:'createTime',
            label:'注册时间',
            show:true,
            slot:'',
        },
        {
            prop:'production',
            label:'年产量',
            show:true,
            slot:'',
        },
        {
            prop:'statusStr',//-1禁用，1、待审核，2已认证
            label:'状态',
            show:true,
            slot:'',
        },
        {
            prop:'carBackUrl',
            label:'身份证反面',
            show:false,
            type:"pic",
            slot:'',
        },
        {
            prop:'carFrontUrl',
            label:'身份证正面',
            show:false,
            type:"pic",
            slot:'',
        },
        {
            prop:'proveUrl',
            label:'信用中国证明',
            type:"pic",
            show:false,
            slot:'',
        },
        {
            prop:'licenseUrl',
            label:'营业执照',
            type:"pic",
            show:false,
            slot:'',
        },
        {
            prop:"creditScore",
            label:'信誉度',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop: "margin",
            label:'保证金',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            width:"200px",
            slot:'operate',
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_demand = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'purchaseOrderNumber',
            label:'计划编号',
            show:true,
            slot:'',
          },
        {
          prop:'material',
          label:'材料名称',
          show:true,
          slot:'',
        },
        {
            prop:'model',
            label:'材料型号',
            show:true,
            slot:'',
        },
        {
            prop:'purchaseCode',
            label:'采购方编号',
            show:true,
            slot:'',
        },
        {
            prop:'purchaseNumber',
            label:'采购数量',
            show:true,
            slot:'',
        },
        {
            prop:'price',
            label:'期望价格',
            show:true,
            slot:'',
        },
        {
            prop:'priceInterval',
            label:'可接受价格浮动',
            show:true,
            slot:'',
        },
        {
            prop:'createTime',
            label:'需求提交时间',
            show:true,
            slot:'',
        },
        {
            prop:'resultsTime',
            label:'成交结果公布时间',
            show:true,
        },
        {
            prop:'statusStr',//1,待公布，2交易成功，交易失败'
            label:'状态',
            show:true,
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_quote = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'purchaseOrderNumber',
            label:'计划编号',
            show:true,
            slot:'',
        },
        {
            prop:'companyCode',
            label:'供应商编号',
            show:true,
            slot:'',
        },
        {
          prop:'material',
          label:'材料名称',
          show:true,
          slot:'',
        },
        {
            prop:'model',
            label:'材料型号',
            show:true,
            slot:'',
        },
        {
            prop:'priceInterval',
            label:'采购价格区间',
            show:true,
            slot:'',
        },
        {
            prop:'number',
            label:'采购数量',
            show:true,
            slot:'',
        },
        {
            prop:'agencyOffer',
            label:'报价',
            show:true,
            slot:'',
        },
        {
            prop:'provideNumber',
            label:'提供数量',
            show:true,
            slot:'',
        },
        {
            prop:'agencyNumber',
            label:'成交数量',
            show:true,
            slot:'',
        },
        {
            prop:'createTime',
            label:'报价时间',
            show:true,
            slot:'',
        },
        {
            prop:'resultsTime',
            label:'成交结果公布时间',
            show:true,
            slot:'',
        },
        {
            prop:'statusStr',//1，待公布，2已成交，3未成交
            label:'状态',
            show:true,
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_plan = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'purchaseOrderNumber',
            label:'计划编号',
            show:true,
            slot:'',
        },
        {
            prop:'purchaseName',
            label:'计划名称',
            show:true,
            slot:'',
            formType:"input",
        },
        {
          prop:'material',
          label:'材料名称',
          show:true,
          slot:'',
          formType:"select",
        },
        {
            prop:'orderModel',
            label:'材料型号',
            show:true,
            slot:'',
        },
        {
            prop:'orderModelId',
            label:'材料型号',
            show:false,
            slot:'',
            formType:"select",
        },
        {
            prop:'purchaseSubmitStartTime',
            label:'采购方需求提交时间',
            show:true,
            slot:'',
            formType:"dateRange",
        },
        {
            prop:'agencyOfferStartTime',
            label:'供应商报价时间',
            show:true,
            slot:'',
            formType:"dateRange",
        },
        {
            prop:'resultPublishTime',
            label:'成交结果公布时间',
            show:true,
            slot:'',
            formType:"date",
        },
        {
            prop:'calculateType',
            label:'分配方式',
            show:false,
            slot:'',
            formType:"select_radio",
        },
        {
            prop:'weightNum',
            label:'数量权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
        {
            prop:'weightPrice',
            label:'价格权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
        {
            prop:'weighScore',
            label:'信誉度权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
        {
            prop:'statusStr',//-1未启用、1启用、2待采集、3采集完成、4供应商开始报价，5供应商报价结束，6待公布，7已交易，8交易完成
            label:'状态',
            show:true,
        }, 
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:"operate",
            width:"280px"
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_autoplan = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'purchaseName',
            label:'规则名称',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'material',
            label:'材料名称',
            show:true,
            slot:'',
            formType:"select",
        },
        {
            prop:'orderModel',
            label:'材料型号',
            show:true,
            slot:'',
        },
        {
            prop:'orderModelId',
            label:'材料型号id',
            show:false,
            slot:'',
            formType:"select",
        },
        {
            prop:'purchaseSubmitStartTime',//注意格式：19 10:28:55
            label:'每月采购方需求提交时间',
            show:true,
            slot:'',
            formType:"dateTime",
        },
        {
            prop:'agencyOfferStartTime',//注意格式：19 10:28:55
            label:'每月供应商报价时间',
            show:true,
            slot:'',
            formType:"dateTime",
        },
        {
            prop:'resultPublishTime',//注意格式：19 10:28:55
            label:'每月成交结果公布时间',
            show:true,
            slot:'',
            formType:"dateTime",
        },
        {
            prop:'statusStr',//'1未启用、2启用'
            label:'状态',
            show:true,
        }, 
        {
            prop:'calculateType',
            label:'分配方式',
            show:false,
            slot:'',
            formType:"select_radio",
        },
        {
            prop:'weightNum',
            label:'数量权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
        {
            prop:'weightPrice',
            label:'价格权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
         {
            prop:'weighScore',
            label:'信誉度权重比例',
            show:false,
            slot:'',
            formType:"input",
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:"operate",
            width:"280px"
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_user = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'userName',
            label:'用户名',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'phone',
            label:'手机号',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'roleStr',
            label:'用户角色',
            show:true,
            slot:'',
            formType:"select",
        },
        {
            prop:'createTime',
            label:'创建时间',
            show:true,
            slot:'',
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:"operate"
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_auth = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'roleName',
            label:'角色',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'remark',
            label:'描述',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'createTime',
            label:'创建时间',
            show:true,
            slot:'',
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:"operate"
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_evaluation = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'content',
            label:'评价内容',
            show:true,
            slot:'',
          },
        {
          prop:'companyName',
          label:'用户名称',
          show:true,
          slot:'',
        },
        {
            prop:'createTime',
            label:'提交时间',
            show:true,
            slot:'',
        },
        {
            prop:'operate',
            label:'操作',
            show:false,
            slot:'operate',
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const tableData_materialAndModel = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'materialName',
            label:'材料名称',
            show:true,
            slot:'',
          },
        {
          prop:'modelStr',
          label:'型号名称',
          show:true,
          slot:'',
        },
        {
            prop:'createTime',
            label:'提交时间',
            show:true,
            slot:'',
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:'operate',
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}
export const formData_materialAndModel = [
    {
        id: "materialName",
        name: "材料名称",
        value: '',
        type: "input",
        check_type: 'required',
        placeholder: "请填写材料名称",
        unit: '',
        slot: "",
        options: [],
        show:true,  
    },
    {
        id: "model1",
        name: "型号1",
        value: '',
        type: "input",
        check_type: 'required',
        placeholder: "请填写型号",
        unit: '',
        slot: "",
        options: [],
        show:true,  
        originData:{}
    }
]
export const tableData_banner = {
    tHead:[
        {
            prop:'index',
            label:'序号',
            show:true,
            slot:'',
            width:'50px',
        },
        {
            prop:'bannerName',
            label:'名称',
            show:true,
            slot:'',
            formType:"input",
        },
        {
            prop:'bannerUrl',
            label:'地址',
            show:true,
            slot:'imgPre',
            formType:"input",
        },
        {
            prop:'createTime',
            label:'创建时间',
            show:true,
            slot:'',
        },
        {
            prop:'operate',
            label:'操作',
            show:true,
            slot:"operate"
        },
    ],
    tBody:[
        {index: 0}
    ],
    page:{
        show:true,
        total:0,
        pageSize:15,
    }
}