<template>
    <div class="material-and-model">
        <div class="header-search">
            <span class="search-item">
              <span class="search-name">材料名称:</span>
              <comInput class="input-width" v-model="materialData.value" :data='materialData'></comInput>
            </span>
            <span class="operate-btn">
              <fontButton @click="search">查询</fontButton>
              <fontButton @click="reset">重置</fontButton>
              <fontButton @click="goAdd">新增</fontButton>
            </span>
          </div>
          <div class="table-main">
            <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
                <template slot="operate" slot-scope="data">
                    <span @click="goEdit(data.data.row)" class="table-btn">编辑</span>
                </template>
            </ContentTable>
          </div>
    <newAndEdit :formType="formType" :formData="formData" :show.sync="formShow" :title="formTitle"  
        :addShow="true" @addData="addData"  @saveData="newAndEditSave"></newAndEdit>
    </div>
</template>
<script>
import {tableData_materialAndModel, formData_materialAndModel} from "../common/data"
import basePage from "../common/basePage.vue"
import {ajax_get_materialAndmodelList, ajax_post_materialAndmodelAdd, ajax_post_materialAndmodelUpdate} from "../../../api/index"
export default {
    name:"materialAndModel",
    mixins: [basePage],
    data(){
        return {
            materialData:{
                placeholder: "请输入材料名称",
                value: ""
            }
        }
    },
    created(){
      this.pageInit(); 
    },
    methods:{
        pageInit(){
          this.ajaxListMethods = ajax_get_materialAndmodelList;
          this.tableData = this.$deepClone(tableData_materialAndModel);
          this.formDataOrigin = this.$deepClone(formData_materialAndModel)
          this.ajax_list_get(true)
        },
        search(){
            this.ajaxGetData.materialName = this.materialData.value
            this.ajax_list_get(true)
        },
        handerTableData(list){
          let resList = [];
          list.forEach((element,index)=>{
            let strRes = "";
            if(element.models.length){
              let str = ""
              element.models.forEach(v=>{
                str+= v.modelName + ","
              })
              let arr = str.split(",")
              arr.splice(arr.length-1,1)
              strRes = arr.join()
            }
            element.modelStr = strRes
            element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
            resList.push(element)
          })
          return resList;
        },
        reset(){
            this.materialData.value = ""
        },
        goEdit(data){
          this.editId = data.id;
          this.formInit(false)
          this.selfTableToForm(data);
        },
        selfTableToForm(data){
          console.log(data,"data")
          this.formData[0].value = data.materialName;
          const len = data.models.length;
          if(len > 1){
            for(let i=0; i<len-1; i++){
              this.addData()
            }
          }
          for(let i=1; i<this.formData.length; i++){
            let item = this.formData[i]
            item.value = data.models[i-1].modelName
            item.originData = data.models[i-1]
          }
        },
        goAdd(){
          this.editId = "";
          this.formInit(true)
        },
        newAndEditSave(){
          let saveMethods = this.formType==="add" ? ajax_post_materialAndmodelAdd : ajax_post_materialAndmodelUpdate
          const params = this.formToSubmit()
          if(this.formType==="edit") params.id = this.editId;
          saveMethods(params).then(res=>{
            if(res.code == 200){
              this.$message.success("操作成功")
              this.formShow = false;
              this.ajax_list_get()
            }
          }).catch(err=>{
            console.log("ajax_err:",err)
          })
        },
        formToSubmit(){
          let obj = {};
          obj.materialName = this.formData[0].value
          obj.models = []
          for(let i = 1; i<this.formData.length; i++ ){
            let item = this.formData[i]
            let jbj = {...item.originData};
            jbj.modelName = item.value
            obj.models.push(jbj)
          }
          return obj
        },
        addData(){
          let base = this.$deepClone(this.formDataOrigin[1])
          base.value = "";
          base.originData = {};
          base.name = "型号" + this.formData.length;
          base.props = "model" + this.formData.length;
          this.formData.push(base)
        }
    }
}
</script>
<style lang="less">
.material-and-model{
    width: 100%;
    height: 100%;
    .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    .search-item{
      margin-right: 15px;
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
    }
    .operate-btn{

    }
  }
}
</style>